import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine } from 'aurelia-framework';
import { activationStrategy, Router } from 'aurelia-router';
import { Models } from 'models/core';
import { UserModels } from 'models/UserModels';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ListSettingsService } from 'services/list-settings-service';
import { NetOfferService } from 'services/net-offer-service';
import { OfferStatusService } from 'services/offer-status-service';
import { ToastService } from 'services/toast-service';
import { UserService } from 'services/user-service';
import { Filters, filterTypes } from '../../elements/Filter';
import { DesignTypeService } from 'services/design-type-service';
import { DimensionClassService } from 'services/dimension-class-service';
import { I18N } from 'aurelia-i18n';
import { Field } from 'components/service/service-list/fields';

const GetCallbackData = <T>(cb: () => Promise<T>) => {
  return new Promise((res: (i: T) => unknown) => {
    setTimeout(async () => {
      res(await cb());
    });
  });
};
@autoinject
export class OfferListTable {
  private tableData: Array<any>;
  private netOfferToUpdate: Models.NetOffer;
  private customers: Array<Models.Customer> = [];
  private searchInputHasFocus: boolean = false;
  private statuses: Array<Models.OfferStatus> = [];
  private userData: UserModels.User;
  private totalItems;
  private ready: boolean = false;

  private OFFER_FILTERS_KEY = 'OFFER_FILTERS_KEY';
  private OFFER_FILTERER_KEY = 'OFFER_FILTERER_KEY';

  @bindable private userId: string = '';

  @bindable private params: any;
  @bindable private pageSize = 25;
  @bindable private context: string = '';
  @bindable private currentPage: number = 1;
  @bindable private customerid: number;

  private showSearchDefault: boolean = false;

  private tableViewType: number = 1;
  private tableViewVerticalLines: boolean = false;
  private tableMargin: number = 2;

  private filters = {
    offerStatusIds: null,
    customerId: null,
    responsibleUserId: '',
    searchText: null,
    orderBy: 'created',
    orderByDirection: 'DESC',
    showCombiNet: null,
    showCollect: null,
    top: this.pageSize,
    skip: null,
  };

  // statuses (id) to be disabled at startup
  protected disabledStatuses = [8, 9, 10];

  constructor(
    private bindingEngine: BindingEngine,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netOfferService: NetOfferService,
    private offerStatusService: OfferStatusService,
    private designTypeService: DesignTypeService,
    private dimensionClassService: DimensionClassService,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private listSettingsService: ListSettingsService,
    private translator: I18N
  ) {}

  protected filterGetFiltersQuery: () => Promise<any>;
  protected filterClearAll: () => void;
  protected filterClearSingle: (name: string) => void;
  protected filterToggleVisible: () => void;
  protected setFilterValueByName: (name: string, data: any) => void;
  protected fields: Field[];
  protected selectedFields: Field[];

  protected defaultFilters = {
    customerIds: true,
    designTypeIds: true,
    dimensionClassIds: true,
    statusIds: true,
    responsibleIds: true,
    showCombiNet: false,
    showCollect: false,
  };

  protected currentFilters: any = {};

  protected getFiltererKey() {
    return 'OFFER_FILTERER_KEY';
  }

  private determineActivationStrategy() {
    return activationStrategy.replace;
  }

  private getFilterKey() {
    return this.OFFER_FILTERS_KEY + (this.context ? '_' + this.context : '');
  }

  async activate() {
    await this.setupFilters();
  }

  private attached() {
    this.setupFields();
    Promise.all([this.offerStatusService.getAllCached(), this.userService.getCurrentUser(), this.listSettingsService.getDefaultPageSize()])
      .then((responses) => {
        this.userData = responses[1];
        this.userId = this.userData.Id;

        if (localStorage.getItem(this.getFilterKey())) {
          this.filters = JSON.parse(localStorage.getItem(this.getFilterKey()));
        } else {
          // this will be used if no previous filters have been set by the user in localStorage
          if (this.context === 'sales-dashboard') {
            this.filters.top = this.pageSize;
            this.filters.responsibleUserId = this.userId;
          }

          this.saveFilters();
        }

        // if the filters are running in the context of a customer, add customer filter
        if (this.customerid) {
          this.filters.customerId = this.customerid;
        }

        // Get current page from query string
        if (this.params.currentPage > 1) {
          this.filters.skip = (this.params.currentPage - 1) * this.filters.top;
          this.currentPage = this.params.currentPage;
        } else {
          this.filters.skip = 0;
        }

        if (this.filters.searchText && this.filters.searchText !== '') {
          this.showSearchDefault = true;
        }

        this.bindingEngine.propertyObserver(this.filters, 'responsibleUserId').subscribe((newValue, oldValue) => {
          this.filterValueChanged('responsibleUserId', newValue, oldValue);
        });

        this.bindingEngine.propertyObserver(this.filters, 'searchText').subscribe((newValue, oldValue) => {
          this.filterValueChanged('searchText', newValue, oldValue);
        });

        // list settings
        this.pageSize = responses[2];

        this.getNetOffers();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getFilteredStatuses() {
    const a = [];
    this.statuses.forEach((status) => {
      if (status.Visible) {
        a.push(status.Id);
      }
    });
    return a;
  }

  showOffer(row) {
    this.router.navigateToRoute(row.IsSimpleOffer ? 'simple-offer-detail' : 'offer-detail', {
      Id: row.Id,
      NetId: row.NetId,
    });
  }

  private sortTable(field) {
    if (this.filters.orderBy === field) {
      this.filters.orderByDirection = this.filters.orderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.filters.orderBy = field;
      this.filters.orderByDirection = 'DESC';
    }

    this.saveFilters();
    this.pushState();

    this.getNetOffers();
  }

  private closeFilter() {
    this.closeDropdown(null);
  }

  private updateVisibleStatuses(event) {
    const statuses = this.getFilteredStatuses();
    const oldStatuses = this.filters.offerStatusIds;
    this.filters.offerStatusIds = statuses;
    this.filterValueChanged('offerStatusIds', statuses, oldStatuses);
  }

  private allVisibleStatuses(event) {
    this.statuses.forEach((status) => {
      status.Visible = true;
    });

    this.updateVisibleStatuses(null);
  }

  private noVisibleStatuses(event) {
    this.statuses.forEach((status) => {
      status.Visible = false;
    });

    this.updateVisibleStatuses(null);
  }

  private filterValueChanged(key, newValue, oldValue) {
    if (newValue === oldValue) {
      return;
    }

    // Always go back to first page when changing filter values
    this.currentPage = 1;

    this.pushState();

    this.saveFilters();

    this.getNetOffers();
  }

  private saveFilters() {
    localStorage.setItem(this.getFilterKey(), JSON.stringify(this.filters));
  }

  private pushState() {
    const stateObj = {
      currentPage: this.currentPage,
    };

    const baseUrl = '#/offers';

    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'offers', baseUrl + '?' + queryString);

    return stateObj;
  }

  private buildQueryString(obj) {
    const arr = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        // dont include keys where value is null
        if (obj[p]) {
          arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    }
    return arr.join('&');
  }

  private currentPageChanged(newValue, oldValue) {
    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.filters.skip = (newValue - 1) * this.filters.top;

    this.saveFilters();
    this.pushState();

    this.getNetOffers();
  }

  private dateHasPassed(date) {
    const now: Date = new Date();
    date = new Date(date);

    if (date < now) {
      return true;
    } else {
      return false;
    }
  }

  async getNetOffers() {
    try {
      setTimeout(async () => {
        const filter = await this.getFilterValues();
        const allFilters = { ...this.filters, ...filter };
        allFilters.select = this.fields
          .filter((x) => x.selected)
          .map((i) => i.field)
          .join(',');

        // Hent tilbud
        this.filters.top = this.pageSize;

        const response = await this.netOfferService.getList(allFilters);

        this.totalItems = Number.parseInt(response.headers.get('x-total-count'));
        this.ready = true;
        this.tableData = await response.json();
      });
    } catch (error) {
      this.errorService.handleError(error);
    }
  }

  private customerIdToName(id) {
    return this.customers.filter((customer) => customer.Id === id).map((customer) => customer.Name);
  }

  private setSearchFocus(event) {
    if (event.detail) {
      this.searchInputHasFocus = event.detail.value;
    }
    if (!this.searchInputHasFocus) {
      this.filters.searchText = null;
      this.getNetOffers();
    }
  }

  private toggleStatuses(event) {
    event.stopPropagation();
  }

  private changeNetOfferStatus(netofferId, statusId) {
    this.ready = false;
    this.netOfferService
      .get(netofferId)
      .then((res) => {
        this.netOfferToUpdate = res;
        this.netOfferToUpdate.OfferStatusId = statusId;
        this.netOfferService
          .put(this.netOfferToUpdate, netofferId)
          .then((result) => {
            this.toastService.showSuccess('offer.updated');
            this.getNetOffers();
          })
          .catch((err) => this.errorService.handleError(err));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private closeDropdown(event) {
    this.eventAggregator.publish('dropdownClose', 1);
  }

  protected onFilterChanged() {
    void this.getNetOffers();
  }

  getFilterValues() {
    return GetCallbackData(this.filterGetFiltersQuery);
  }

  protected async setupFilters() {
    return await new Promise((res: (v: Filters) => void) => {
      setTimeout(async () => {
        const customersJob = (await this.customerService.getAllCached()).filter((x) => !x.IsDeleted);
        const designTypesJob = (await this.designTypeService.getAllCached()).filter((x) => !x.IsDeleted);
        const dimensionClassJob = (await this.dimensionClassService.getAllCached()).filter((x) => !x.IsDeleted);
        const responsibleJob = await this.netOfferService.getResponsibleUsers();
        const offerStatusJob = (await this.offerStatusService.getAllCached()).filter((x) => !x.IsDeleted);

        const [customers, designTypes, dimensionClasses, responsible, offerStatuses] = await Promise.all([
          customersJob,
          designTypesJob,
          dimensionClassJob,
          responsibleJob,
          offerStatusJob,
        ]);

        const filters: Filters = {
          customerIds: {
            name: 'customerIds',
            label: this.translator.tr('general.customer'),
            type: filterTypes.CHECKBOX,
            options: customers,
            query: 'customerIds',
          },
          designTypeIds: {
            name: 'designTypeIds',
            label: this.translator.tr('notlogg.designtype'),
            type: filterTypes.CHECKBOX,
            options: designTypes,
            query: 'designTypeIds',
          },
          dimensionClassIds: {
            name: 'dimensionClassIds',
            label: this.translator.tr('notlogg.dimensionclass'),
            type: filterTypes.CHECKBOX,
            options: dimensionClasses,
            query: 'dimensionClassIds',
          },
          responsibleIds: {
            name: 'responsibleIds',
            label: this.translator.tr('general.responsibleUser'),
            type: filterTypes.CHECKBOX,
            options: responsible,
            query: 'responsibleIds',
          },
          statusIds: {
            name: 'statusIds',
            label: this.translator.tr('general.status'),
            type: filterTypes.CHECKBOX,
            options: offerStatuses,
            query: 'statusIds',
          },
          showCombiNet: {
            name: 'showCombiNet',
            label: this.translator.tr('general.combiNet'),
            type: filterTypes.RADIO_SIMPLE,
            options: this.translator.tr('general.onlyCombiNet'),
            _visible: false,
            defaultValues: () => {
              return null;
            },
            clear: () => this.filterClearSingle('showCombiNet'),
          },
          showCollect: {
            name: 'showCollect',
            label: this.translator.tr('general.isMorenotCollect'),
            type: filterTypes.RADIO_SIMPLE,
            options: this.translator.tr('general.onlyCollectNet'),
            _visible: false,
            defaultValues: () => {
              return null;
            },
            clear: () => this.filterClearSingle('showCollect'),
          },
        };

        this.currentFilters = filters;
        res(filters);
      });
    });
  }

  protected updateSelectList() {
    const selectedFields = this.fields.filter((x) => x.selected).map((x) => x.field);
    localStorage.setItem('OFFER_LIST_COLUMNS', JSON.stringify(selectedFields));
    this.setupFields();
    void this.getNetOffers();
  }

  protected setupFields() {
    this.fields = fields;
    const selectedFields: Array<string> = JSON.parse(localStorage.getItem('OFFER_LIST_COLUMNS'));

    if (selectedFields) {
      this.fields.forEach((field: Field) => {
        if (field.visible || field.visible == undefined) {
          field.selected = false;
        }
      });

      this.fields.forEach((field: Field) => {
        const selectedField = selectedFields.find((x) => {
          return x == field.field;
        });

        if (selectedField) {
          field.selected = true;
        } else {
          if (field.visible || field.visible == undefined) {
            field.selected = false;
          }
        }
      });
    }

    this.selectedFields = this.fields.filter((x) => x.selected && (x.visible === undefined || x.visible === true));
  }
}

const fields: Field[] = [
  {
    field: 'IsCollect',
    title: 'general.isMorenotCollect',
    disabled: false,
    visible: true,
    selected: false,
  },
  {
    field: 'IsCombiNet',
    title: 'general.combiNet',
    disabled: false,
    visible: true,
    selected: false,
  },
];
